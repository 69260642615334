<template>
  <div>
    <SuspensionConsult />
    <div class="productTop_wrap">
      <div class="productTop_cont">
        <p class="text1">产品名称产品名称</p>
        <p class="text2">
          产品作用产品作用产品作用产品作用产品作用产品作用产品作用
        </p>
        <button class="pointer registered_btn">立即注册</button>
      </div>
      <img
        class="productDetail_wrap_bg"
        src="@/assets/productDetail_bg.png"
        alt=""
      />
    </div>
    <div class="problem_wrap">
      <p class="problem_title">客户遇到什么问题</p>
      <p class="problem_subtitle">
        副标题副标题副标题副标题副标题副标题副标题副标题
        副标题副标题副标题副标题副标题副标题副标题副标题
      </p>
    </div>
    <div class="painPoint_wrap">
      <img class="painPoint_bg" src="@/assets/painPoint_bg.png" alt="" />
      <div class="painPoint_list">
        <div class="painPoint_item" v-for="(item, index) in 6" :key="index">
          <img
            class="item_icon"
            :src="require(`@/assets/painPoint_${index + 1}.png`)"
            alt=""
          />
          <p class="item_msg">
            客户痛点客户痛点客户痛点客户痛点客户痛点
            客户痛点客户痛点客户痛点客户痛点客户痛点 客户痛点客户痛点客户痛点客
          </p>
        </div>
      </div>
    </div>
    <div class="solve_wrap">
      <div class="solve_cont">
        <p class="solve_title">如何帮客户解决</p>
        <p class="solve_subtitle">标题副标题副标题副标题副标题副标</p>
        <div class="solve_box">
          <img class="solve_img" src="@/assets/serve_bg.png" alt="">
          <div class="solve_msg">
            <p class="msg_title">智能排期</p>
            <div class="msg_box">
              <div class="msg_item">
                <p class="item_name">柔性规划生产</p>
                <p class="item_subname">全盘掌握生产信息，辅助柔性排期</p>
              </div>
              <div class="msg_item">
                <p class="item_name">智能推荐排程方案</p>
                <p class="item_subname">云计算推荐方案智能优化生产管理</p>
              </div>
              <div class="msg_item">
                <p class="item_name">掌握生产进度</p>
                <p class="item_subname">精确到任务，事实更新订单计划情况和产生进度，生产书据可视化</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="solve_cont">
        <div class="solve_box">
          <img class="solve_img" src="@/assets/serve_bg.png" alt="">
          <div class="solve_msg">
            <p class="msg_title">智能排期</p>
            <div class="msg_box">
              <div class="msg_item">
                <p class="item_name">柔性规划生产</p>
                <p class="item_subname">全盘掌握生产信息，辅助柔性排期</p>
              </div>
              <div class="msg_item">
                <p class="item_name">智能推荐排程方案</p>
                <p class="item_subname">云计算推荐方案智能优化生产管理</p>
              </div>
              <div class="msg_item">
                <p class="item_name">掌握生产进度</p>
                <p class="item_subname">精确到任务，事实更新订单计划情况和产生进度，生产书据可视化</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="solve_cont">
        <div class="solve_box">
          <img class="solve_img" src="@/assets/serve_bg.png" alt="">
          <div class="solve_msg">
            <p class="msg_title">智能排期</p>
            <div class="msg_box">
              <div class="msg_item">
                <p class="item_name">柔性规划生产</p>
                <p class="item_subname">全盘掌握生产信息，辅助柔性排期</p>
              </div>
              <div class="msg_item">
                <p class="item_name">智能推荐排程方案</p>
                <p class="item_subname">云计算推荐方案智能优化生产管理</p>
              </div>
              <div class="msg_item">
                <p class="item_name">掌握生产进度</p>
                <p class="item_subname">精确到任务，事实更新订单计划情况和产生进度，生产书据可视化</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/footer";
import SuspensionConsult from "@/components/suspensionConsult";
export default {
  components: {
    Footer,
    SuspensionConsult,
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>